<template>
	<div id="tabla">
		<!-- <vx-card > -->

		<div class="row w2-full">
			<div class="card-info-productos">
				<div class="tr-borde">
					<div class="col-md-2 logo-productos">
					<!--	<img :src="'https://cotizador.socasesores.com/uploads/' + item.Logo" :alt="item.Banco" class="recorte2" />-->
            <img :src="'https://cotizador.socasesores.com/uploads/'+ item.Logo" :alt="item.Banco" width="140">
					</div>

					<div class="col-md-3 descripcion-productos">
						<slot name="item-meta">
							<vx-tooltip :text="item.Descripcion" v-if="item.Descripcion">
								<vs-chip type="gradient">Descripcion</vs-chip>
							</vx-tooltip>

							<h6 class="titulo-productos">{{ item.Producto }}</h6>
							<span class="ocultar2">Pago <span class="font-semibold cursor-pointer">{{ item.Esquema == 2 ? 'Fijo' : 'Creciente' }}</span> durante <span class="font-semibold cursor-pointer">
                <a v-if="PrecalificacionTipo ==3">
                  {{ item.Plazo }} meses
                </a>     
                <a v-else>                  
                  {{ item.Plazo / 12 }} años
                </a>           
              </span></span>
							<span class="ocultar mensualidad">
								<span>Mensualidad: &nbsp;</span>
								<span>${{ numberFormat(item.MensualidadPromedio) }}</span>
							</span>
						</slot>
					</div>
					<div class="col-md-1 info-productos">
						<button 
							@click="detalleProducto(item)"
							class="btn btn-infor"
						>
							<svg class="info">
								<g>
									<g transform="translate(0)">
										<g>
											<path id="Vector" d="M9.625,27.5h8.25c6.875,0,9.625-2.75,9.625-9.625V9.625C27.5,2.75,24.75,0,17.875,0H9.625C2.75,0,0,2.75,0,9.625v8.25C0,24.75,2.75,27.5,9.625,27.5Z" transform="translate(2.75 2.75)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
											<path id="Vector-2" data-name="Vector" d="M0,0H33V33H0Z" fill="none" opacity="0" />
										</g>
									</g>
									<g transform="translate(16.5 22.379) rotate(180)">
										<path id="Vector-3" data-name="Vector" d="M0,0V7.219" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
										<path class="i" id="Vector-4" data-name="Vector" d="M0,0V.137" transform="translate(0 11.619)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
									</g>
								</g>
							</svg>
						</button>
						<vs-prompt title="Detalles" class="export-options prompt-modal" :active.sync="activePrompt" ok-only>
							<slot name="item-meta">
								<img :src="'https://cotizador.socasesores.com/uploads/' + item.Logo" :alt="item.Banco" class="recorte" />

                <!--   ACTUALIZACION DE FORMATOS-->                
                <button style="background: #079DEF; color:#FFF; margin-left:80px;" class="btn btn-sm ocultar2 btnocultaim" icon-pack="feather" icon="icon-printer"  @click="DescargarPDF_detalleProducto({item})">
                  Descargar PDF
                </button>

								<vx-tooltip :text="item.Descripcion" v-if="item.Descripcion">
									<vs-chip type="gradient">Descripcion</vs-chip>
								</vx-tooltip>

								<h6 class="titulo">{{ item.Producto }}</h6>
								<span style="color: #00368c">Pago<span class="font-semibold cursor-pointer">{{ item.Esquema == 2 ? 'Fijo' : 'Creciente' }}</span> durante <span class="font-semibold cursor-pointer">                  
                  <a v-if="PrecalificacionTipo ==3">
                  {{ item.Plazo }} meses
                </a>     
                <a v-else>                  
                  {{ item.Plazo / 12 }} años
                </a>       
                </span></span>

								<tr>
									<slot name="mejora"></slot>
								</tr>

                <tr v-if="(destino == 19 || destino == 25 || destino == 22 || destino == 33 || destino == 34)">
									<td class="td-modal">Ahorro mensual:</td>
									<td>${{ numberFormat(item.MejoraMensualidad) }}</td>
                </tr>
                <tr v-if="(destino == 19 || destino == 25 || destino == 22 || destino == 33 || destino == 34)">
									<td class="td-modal">Ahorro total:</td>
									<td>${{ numberFormat(item.AhorroTotal > 0 ? item.AhorroTotal : 0) }}</td>
                </tr>	
                <tr v-if="PrecalificacionTipo == 3">
									<td class="td-modal">Año del vehículo:</td>
									<td>{{ anioVehiculo }}</td>
                </tr>	
                <tr>
                  <td class="td-modal">Mensualidad Inicial:</td>
                  <td v-if="item.BancoId == 33">${{ numberFormat(item.MensualidadPromedio) }}</td>
                  <td v-else>${{ numberFormat(mensualidadInicial) }}</td>
                </tr>

								<tr>
									<td class="td-modal">Mensualidad promedio:</td>
									<td>${{ numberFormat(item.MensualidadPromedio) }}</td>
								</tr>
								<tr>
									<td class="td-modal">Monto de crédito:</td>
                  <td v-if="PrecalificacionTipo == 3 && destino != 45"> {{ numberFormat(montoSolicitadoAuto) }} </td>
                  <td v-else-if="PrecalificacionTipo == 3 && destino == 45"> {{ numberFormat(item.ValorPropiedad) }} </td>
									<td v-else>${{ numberFormat(item.Monto) }}</td>
								</tr>
								<tr>
									<td class="td-modal">Tasa:</td>
									<td>{{ numberFormat(item.Tasa) }}%</td>
								</tr>
								<tr>
									<td class="td-modal">Cat:</td>
									<td>{{ numberFormat(item.Cat) }}%</td>
								</tr></slot>
							<tr>
								<td class="td-modal">Ingreso mínimo:</td>
								<td>${{ numberFormat(item.IngresoMinimo) }}</td>
							</tr>
							<tr>
								<td class="td-modal">Pago Total:</td>
								<td>${{ numberFormat(pagoMensualTotal) }}</td>
							</tr>
							<tr>
								<td class="td-modal">Aforo:</td>
								<td>{{ numberFormat(item.Aforo) }}%</td>
							</tr>
						
							<slot name="cofinavit"></slot>
							<tr>
								<slot name="gastos"></slot>
							</tr>

							<tr v-if="PrecalificacionTipo != 3">
								<td class="td-modal">Avaluo:</td>
								<td>${{ numberFormat(item.Avaluo) }}</td>
							</tr>
							<tr>
								<td class="td-modal">Comisión por apertura:</td>
								<td>${{ numberFormat(item.ComisionApertura) }}</td>
							</tr>
							<tr v-if="PrecalificacionTipo != 3">
								<td class="td-modal">Gastos notariales:</td>
								<td>${{ numberFormat(item.GastosNotariales) }}</td>
							</tr>
							<tr>
								<td class="td-modal">Enganche</td>
                <td v-if="PrecalificacionTipo == 3 && destino != 45">${{ numberFormat(engancheAuto) }}</td>
                <td v-else-if="PrecalificacionTipo == 3 && destino == 45">${{ numberFormat(item.ValorPropiedad) }}</td>
								<td v-else>${{ numberFormat(item.Enganche) }}</td>
							</tr>
						</vs-prompt>
					</div>
					<div class="col-md-2 acciones">
						<!--<h5 class="text-center" v-if="filtroId!==5">{{filtroName}}</h5>-->
						<h5 class="font-bold text-center" v-if="filtroId == 1 && item.BancoId != 33">$ {{ numberFormat(item.MensualidadInicialPromedio) }}</h5>
            <h5 class="font-bold text-center" v-else-if="filtroId == 1 && item.BancoId == 33">$ {{ numberFormat(item.MensualidadPromedio) }}</h5>
						<h5 class="font-bold text-center" v-else-if="filtroId == 9">{{ item.Banco }}</h5>
						<h5 class="font-bold text-center" v-else-if="filtroId == 2">{{ numberFormat(item.Cat) }} %</h5>
						<h5 class="font-bold text-center" v-else-if="filtroId == 3">{{ numberFormat(item.Tasa) }} %</h5>
						<h5 class="font-bold text-center" v-else-if="filtroId == 4">$ {{ numberFormat(item.PagoTotal) }}</h5>
						<h5 class="font-bold text-center" v-else-if="filtroId == 6">$ {{ numberFormat(item.IngresoMinimo) }}</h5>
						<h5 class="font-bold text-center" v-else-if="filtroId == 7 && item.BancoId != 21">$ {{ numberFormat(item.AhorroTotal) }}</h5>
						<h5 class="font-bold text-center" v-else-if="filtroId == 8 && item.BancoId != 21">$ {{ numberFormat(item.MejoraMensualidad) }}</h5>
						<h5 class="font-bold text-center" v-else-if="filtroId == 5 && item.BancoId != 21">{{ item.vctPromociones.length > 0 ? item.vctPromociones.length : 'Sin ' }} promociones</h5>
					</div>
					<div class="col-md-2 acciones-productos">
						<div class="d-flex justify-content-center">
							<div class="icono-tabla">
           
              	<button class="favoritos" type="line" @click="tablaAmortizacionBancos(item)">
                
            
							
									<svg class="note">
										<g id="note">
											<path id="Vector" d="M0,0V4.188" transform="translate(11.167 2.792)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
											<path id="Vector-2" data-name="Vector" d="M0,0V4.188" transform="translate(22.333 2.792)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
											<path id="Vector-3" data-name="Vector" d="M0,0H11.167" transform="translate(9.771 18.146)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
											<path id="Vector-4" data-name="Vector" d="M0,0H6.979" transform="translate(9.771 23.729)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
											<path id="Vector-5" data-name="Vector" d="M18.146,0c4.648.251,6.979,2.024,6.979,8.584v8.626c0,5.751-1.4,8.626-8.375,8.626H8.375C1.4,25.837,0,22.961,0,17.211V8.584C0,2.024,2.331.265,6.979,0Z" transform="translate(4.188 4.885)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
										</g>
									</svg>
								</button>
								<div class="ocultar leyenda-tabla"><br />Tabla de pagos</div>
							</div>
							<div class="icono-favoritos">
								<slot name="action-buttons-2" />
								<div class="ocultar leyenda-favoritos"><br />Favoritos</div>
							</div>
						</div>
					</div>
					<div class="col-md-1 promocion-productos">
						<div class="d-flex justify-content-center">
							<vx-tooltip color="primary" :text="'PROMOCIÓN: ' + promos" v-for="promos in item.vctPromociones" :key="promos">
								<svg class="star">
									<path id="Vector" d="M15.639,1.91,17.985,6.6A2.9,2.9,0,0,0,19.879,8l4.253.707c2.72.453,3.36,2.427,1.4,4.373L22.225,16.39a2.9,2.9,0,0,0-.693,2.413l.947,4.093c.747,3.24-.973,4.493-3.84,2.8l-3.987-2.36a2.882,2.882,0,0,0-2.64,0L8.025,25.7c-2.853,1.693-4.587.427-3.84-2.8L5.132,18.8a2.9,2.9,0,0,0-.693-2.413L1.132,13.083c-1.947-1.947-1.32-3.92,1.4-4.373L6.785,8a2.9,2.9,0,0,0,1.88-1.4L11.012,1.91C12.292-.637,14.372-.637,15.639,1.91Z" transform="translate(2.668 2.77)" stroke="#e2b41a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
								</svg>
							</vx-tooltip>
						</div>
					</div>
					<div class="col-md-1 favoritos-productos">
						<slot name="action-buttons" />
					</div>
				</div>

				<!-- IMG COL -->

				<!-- ITEM NAME & DESC COL -->
				<div class="vx-col sm:w-1/2">
					<div class="p-4 pt-6">
						<vs-popup fullscreen title="Tabla de amortización" :active.sync="popupActiveExc" id="popupAmortizacion" @cancle="clearFields" @close="clearFields">
							<vs-prompt title="Exportar a Excel" class="export-options" cancel-text="Cancelar" @cancle="clearFields" @accept="exportToExcel" accept-text="Exportar" @close="clearFields" :active.sync="activePromptExc">
								<input v-model="fileName" placeholder="Nombre del archivo" class="w-full" />
								<v-select v-model="selectedFormat" :options="formats" class="my-4" />
								<div class="flex">
									<span class="mr-4">Tamaño de la celda automático:</span>
									<vs-switch v-model="cellAutoWidth">Tamaño automático</vs-switch>
								</div>
							</vs-prompt>

              <template >
                <div>
                    <vs-button @click="descargarPDF(item , ans)" class="exportPDF">Descargar PDF</vs-button>

  <!--                  <vs-button @click="descargarPDF(item)" class="tablaAmortizacion btn-sm" style="padding: 1px 10px 1px 10px;"> Tabla de Amortizacion <br> 2</vs-button>-->
                  <div v-if="item.DobleTablaAmortizacion">
                    <vs-button class="tablaAmortizacion btn-sm" style="padding: 1px 10px 1px 10px;" @click="tablaAmortizacionBancos(item, 'TablaAmortizacion2'), toggleSave()" v-bind:hidden="!tablaDeAmortizacion">Tabla de Amortizacion <br> 2</vs-button> 
                    <vs-button v-bind:hidden="tablaDeAmortizacion" class="tablaAmortizacion btn-sm" style="padding: 1px 10px 1px 10px;" @click="tablaAmortizacionBancos(item, 'TablaAmortizacion1'), toggleSave()">Tabla de Amortizacion <br> 1</vs-button>                  
                  </div>

                    <vs-popup fullscreen title="Solicitud de crédito" :active.sync="popupSolicitud">
                      <embed src="" type="application/pdf" width="100%" height="1000px" />
                    </vs-popup>

                    <vs-button v-if="isCasoCompleto == true" @click="sendEmail(item)" class="export" >Enviar por correo</vs-button>
                  </div>
                    <br><br><br>
                <div v-if="item.DobleTablaAmortizacion"  class="labelAmortizacion" id="labelAmortizacion">
                  <div v-if="this.labelPDF == 'TABLA DE AMORTIZACION 2' " > Tabla de Amortizacion 2 </div> 
                  <div v-else> Tabla de Amortizacion 1 </div> 
                </div> 
                
              </template>  

              <vs-table :data="itemsTable" noDataText="No hay datos para mostrarr" v-if="ministraciones == 'Si' && showMinistraciones > 0">
                <template slot="thead">
                  <vs-th> Mes </vs-th>
									<vs-th> Ministraciones </vs-th>
									<vs-th> Tasa </vs-th>
									<vs-th> Intereses </vs-th>
									<vs-th> Amortización </vs-th>									
									<vs-th> Mensualidad </vs-th>	

									<vs-th v-if="item.BancoId != 33"> Seg. Vida </vs-th>
									<vs-th v-if="item.BancoId != 33"> Seg. Daños </vs-th>
									<vs-th v-if="item.BancoId != 33"> Seg. Desempleo </vs-th>
									<vs-th v-if="item.BancoId != 33"> Comisión </vs-th>

                  <vs-th v-if="item.BancoId == 33"> Seguros y Otros </vs-th>

									<vs-th> Pago Mensual </vs-th>
                  
                </template>

                
								<template slot-scope="{ data }">
									<vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in itemMinitraciones">
										<vs-td :data="itemMinitraciones[indextr].Mes">                      
                     {{ itemMinitraciones[indextr].Mes }} 
										</vs-td>

										<vs-td :data="itemMinitraciones[indextr].Ministraciones"> $  {{ numberFormat(itemMinitraciones[indextr].Ministraciones) }}  </vs-td>

										<vs-td :data="itemMinitraciones[indextr].Tasa"> {{ numberFormat(itemMinitraciones[indextr].Tasa) }}% </vs-td>

										<vs-td :data="itemMinitraciones[indextr].Interes"> $ {{ numberFormat(itemMinitraciones[indextr].Interes) }} </vs-td>
										<vs-td :data="itemMinitraciones[indextr].Amortizacion"> ${{ numberFormat(itemMinitraciones[indextr].Amortizacion) }} </vs-td>
										<vs-td :data="itemMinitraciones[indextr].Mensualidad"> $ {{ numberFormat(itemMinitraciones[indextr].Mensualidad) }} </vs-td>
										<!-- <vs-td :data="itemMinitraciones[indextr].GastosDiferidos"> $ {{ numberFormat(itemMinitraciones[indextr].GastosDiferidos) }} </vs-td> -->

										<vs-td v-if="item.BancoId != 33"  :data="itemMinitraciones[indextr].SegVida"> $ {{ numberFormat(itemMinitraciones[indextr].SegVida) }} </vs-td>
										<vs-td v-if="item.BancoId != 33"  :data="itemMinitraciones[indextr].SegDanio"> $ {{ numberFormat(itemMinitraciones[indextr].SegDanio) }} </vs-td>
										<vs-td v-if="item.BancoId != 33"  :data="itemMinitraciones[indextr].SegDesempleo"> $ {{ numberFormat(itemMinitraciones[indextr].SegDesempleo) }} </vs-td>
										<vs-td v-if="item.BancoId != 33"  :data="itemMinitraciones[indextr].SegComision"> $ {{ numberFormat(itemMinitraciones[indextr].SegComision) }} </vs-td>

                     <vs-td v-if="item.BancoId == 33" :data="itemMinitraciones[indextr].GastosDiferidos"> $ {{ numberFormat(itemMinitraciones[indextr].GastosDiferidos) }} </vs-td>

										<vs-td :data="itemMinitraciones[indextr].PagoMensual"> $ {{ numberFormat(itemMinitraciones[indextr].PagoMensual) }} </vs-td>
									</vs-tr>
								</template>
              </vs-table>


							<vs-table :data="itemsTable" max-items="12" pagination noDataText="No hay datos para mostrar">
              
                
								<template slot="thead">                  
									<vs-th> Mes </vs-th>
									<vs-th> Capital </vs-th>
									<vs-th> Tasa </vs-th>
									<vs-th> Intereses </vs-th>
									<vs-th> Amortización </vs-th>
									<!-- <vs-th> Mensualidad Inicial</vs-th> -->
									<vs-th> Mensualidad </vs-th>
									<!-- <vs-th> Seguros y Otros </vs-th> -->
									<vs-th v-if="item.BancoId != 33"> Seg. Vida </vs-th>
									<vs-th v-if="item.BancoId != 33"> Seg. Daños </vs-th>
									<vs-th v-if="item.BancoId != 33"> Seg. Desempleo </vs-th>
									<vs-th v-if="item.BancoId != 33"> Comisión </vs-th>                   

                   <vs-th v-if="item.BancoId == 33"> Seguros y Otros </vs-th>

									<vs-th> Pago Mensual </vs-th>
								</template>

								<template slot-scope="{ data }">
									<vs-tr 
                    :data="tr" 
                    :key="indextr" v-for="(tr, indextr) in data" 
                    :class="{ 'aportacion-indicador': aportacionesFilas.find(m => m == data[indextr].Mes) }"> <!--marca aportacion-->
										<vs-td :data="data[indextr].Mes">
											{{ data[indextr].Mes }}
										</vs-td>

										<vs-td :data="data[indextr].Capital"> $ {{ numberFormat(data[indextr].Capital) }} </vs-td>

										<vs-td :data="data[indextr].Tasa"> {{ numberFormat(data[indextr].Tasa) }}% </vs-td>

										<vs-td :data="data[indextr].Interes"> $ {{ numberFormat(data[indextr].Interes) }} </vs-td>
										<vs-td :data="data[indextr].Amortizacion"> ${{ numberFormat(data[indextr].Amortizacion) }} </vs-td>
										<vs-td :data="data[indextr].Mensualidad"> $ {{ numberFormat(data[indextr].Mensualidad) }} </vs-td>
										<!-- <vs-td :data="data[indextr].GastosDiferidos"> $ {{ numberFormat(data[indextr].GastosDiferidos) }} </vs-td> -->

										<vs-td v-if="item.BancoId != 33" :data="data[indextr].SegVida"> $ {{ numberFormat(data[indextr].SegVida) }} </vs-td>
										<vs-td v-if="item.BancoId != 33" :data="data[indextr].SegDanio"> $ {{ numberFormat(data[indextr].SegDanio) }} </vs-td>
										<vs-td v-if="item.BancoId != 33" :data="data[indextr].SegDesempleo"> $ {{ numberFormat(data[indextr].SegDesempleo) }} </vs-td>
										<vs-td v-if="item.BancoId != 33" :data="data[indextr].SegComision"> $ {{ numberFormat(data[indextr].SegComision) }} </vs-td>

                    <vs-td v-if="item.BancoId == 33" :data="data[indextr].GastosDiferidos">$ {{ numberFormat(data[indextr].GastosDiferidos) }} </vs-td>

										<vs-td :data="data[indextr].PagoMensual"> $ {{ numberFormat(data[indextr].PagoMensual) }} </vs-td>
									</vs-tr>
								</template>
							</vs-table>
							<div class="">
								<h5>Totales</h5>
								<vs-table :data="itemsTable">
									<template slot="thead">
										<vs-th> Meses Totales </vs-th>
										<vs-th> Monto de crédito </vs-th>
										<vs-th> Tasa </vs-th>
										<vs-th> Suma intereses </vs-th>
										<vs-th> Suma amortización </vs-th>
										<vs-th> Suma mensualidad </vs-th>
										<!-- <vs-th> Gastos totales </vs-th> -->
										<vs-th  v-if="item.BancoId != 33"> Suma Seg. Vida </vs-th>
										<vs-th  v-if="item.BancoId != 33"> Suma Seg. Daños </vs-th>
										<vs-th  v-if="item.BancoId != 33"> Suma Seg. Desempleo </vs-th>
										<vs-th  v-if="item.BancoId != 33"> Suma Comisión </vs-th>

                    <vs-th v-if="item.BancoId == 33"> Gastos totales </vs-th>


										<vs-th v-if="item.BancoId != 21"> Pago Mensual total </vs-th>
										<vs-th v-if="item.BancoId == 21"> Pago total </vs-th>
									</template>

									<template>
										<vs-tr>
											<vs-td>
												{{ mesesTotales }}
											</vs-td>
											<vs-td> $ {{ numberFormat(amortizacionTotal) }} </vs-td>
											<vs-td> {{ numberFormat(tasa) }}% </vs-td>
											<vs-td> $ {{ numberFormat(interesesTotales) }} </vs-td>
											<vs-td> $ {{ numberFormat(amortizacionTotal) }} </vs-td>
											<vs-td> $ {{ numberFormat(mensualidadTotal) }} </vs-td>
											<!-- <vs-td> $ {{ numberFormat(gastosTotales) }} </vs-td> -->
											<vs-td v-if="item.BancoId != 33" > $ {{ numberFormat(sumaSegVida) }} </vs-td>
											<vs-td v-if="item.BancoId != 33" > $ {{ numberFormat(sumaSegDanio) }} </vs-td>
											<vs-td v-if="item.BancoId != 33" > $ {{ numberFormat(sumaSegDesempleo) }} </vs-td>
											<vs-td v-if="item.BancoId != 33" > $ {{ numberFormat(sumaSegComision) }} </vs-td>

                      <vs-td v-if="item.BancoId == 33"> $ {{ numberFormat(gastosTotales) }} </vs-td>

											<vs-td> $ {{ numberFormat(pagoMensualTotal) }} </vs-td>
										</vs-tr>
									</template>
								</vs-table>
							</div>
						</vs-popup>
					</div>
				</div>
				<!-- PURCHASE COL -->
			</div>
		</div>
		<!--</vx-card>-->
	</div>
</template>
<script>
import vSelect from 'vue-select'
import axios from 'axios';
export default{
  components: {
    vSelect 
  },
  props: {
     idCaso: {
       type: Number,
       required: false
     },
    item: {
      type: Object,
      required: true
    },
    destino: {
      type: Number,
      required: true
    },
    user: {
      type: String,
      required: false
    },
    filtroName:{
      type: String,
      required: false
    },
    filtroId:{
      type: Number,
      required: false
    },
    ingreso: {
      type: Object,
      required: false
    },
    ministraciones :{
      type: String,
      required: false
    },
    plazoMinistracion:{
      type: Number,
      required: false
    }
  },
  data(){
		return{
		
			itemId:'',
			solicitante:'',
			fileName: '',
			formats:['xlsx', 'csv', 'txt'],
			cellAutoWidth: true,
			selectedFormat: 'xlsx',
			headerTitle: ['Mes', 'Capital', 'Tasa', 'Interes', 'Amortizacion','Mensualidad','Gastos','Pago'],
			headerVal: ['Mes', 'Capital', 'Tasa', 'Interes', 'Amortizacion','Mensualidad','GastosDiferidos','PagoMensual'],
			popupActive: false,
			popupActiveExc: false,
			activePrompt: false,
			activePromptExc: false,
			itemsTable:[],
      itemMinitraciones: [],
      mesMinistracion : 1,
      showMinistraciones: '',
			interesesTotales:0,
			mensualidadTotal:0,
			mesesTotales:0,
			montoSolicitado:0,
			amortizacionTotal:0,
			gastosTotales:0,
			pagoMensualTotal:0,

			sumaSegVida: 0,
			sumaSegDanio: 0,
			sumaSegDesempleo: 0,
			sumaSegComision: 0,

      aportaciones: {
        tieneAportacion: "No",
        plazoAportacion: 0, // 1: mensual, 2: bimestral, 3: semestral, 4: anual
        cantidadAportacion: 0
      },
      aportacionesFilas: [],

      mensualidadInicial: 0,
      gastoNotarialCalculado:0,
      gastoNotarialPorcentaje:0,
      tasa:0,
      urlAmotizacion:'',
      popupSolicitud:false,
      broker: '',
      isCasoCompleto : false,
      tablaDeAmortizacion: true,      
      destinoTablaAmortizacion2 : '',      
      caso: {
        destino: '',
        plazos: [],
        esquemas :[],
        bancos: [],        
      },
      caso2: {
        destino: '',
        plazos: [],
        esquemas :[],
        bancos: [], 
      },
      bancos: [],
      labelOrigen :'',
      banderaTabla1: false,
      id_variante: '',
      labelPDF : '', 
      ans: '',  
      gastosIniciales: '' ,
      SocGuid:'',  
       header: {
          "authorization": "Bearer 16JXvQ9CD1eEoVESY3-4d-bElrAAgAfcB0UgYwAB-XyuNugkcBvgntr6n6VjEAFWenS9Exi_jlLv_RBfmf004TXytHP64dNEuSyhlXOBDtO56hW07txWd4evNWfukK5CO1ETRIOk8GWyjjS7daQ8eLHUz3XefoKEHKE0yIy76o8rZZYTGskAn1Zo3oKcPFzfs_fmVlO15cD_Kxx27-1qqDBsfAphhY7N599ly5ppo5L4XHaL8JAv6mTxXVYxrTZPQDmnLqQ9Nwre7Bd4URM5rR3rVMrAVkPzJ8ohQ9-pgvigB-Fra_SMi31c5T-QrSTB"
        },  
      PrecalificacionTipo : '',
      anioVehiculo: ''   
    }
  },
  mounted(){    
    if (this.idCaso) {    
      this.id=this.idCaso;
    }else{      
      this.id=this.$route.params.id
    }        
    this.getApikey()
    this.DameCredenciales()
    if(this.id){      
      this.dameCaso()            
    }
    if (!this.plazoMinistracion) {
      this.plazoMinistracion = 0
    } 
    console.log("producto.vue")
    console.log(this.item)   
  },
  computed:{
    socData(){
      //return this.$store.state.socData
      return JSON.parse(localStorage.getItem('socData'))
    },
    incumplidas:function(){
      if (this.item.vctIncumplidas.length) {
        let text=''
        for (var i = 0; i < this.item.vctIncumplidas.length; i++) {
          let j=i+1
          text=text + ' '+ j +':'+this.item.vctIncumplidas[i]
        }//console.log(text)
        return text
      }
    }    
  },
  methods: {
    toggleSave() {
			this.tablaDeAmortizacion = !this.tablaDeAmortizacion;
		},
    detalleProducto(item){
      if(item.BancoId==33){
        this.tablaAmortizacionKredi(item) 

      }else{
        this.getTotalProducto(item) 
      }

    },
    tablaAmortizacionBancos(item, origen){ 
      
      // console.log( 'MarcacionAportacion', this.aportaciones );
      if (this.aportaciones.tieneAportacion == "Si") {
        switch (this.aportaciones.plazoAportacion) {
          case 1:
            let mes = 0;
            for (let z = 0; z < this.aportaciones.cantidadAportacion; z++) {
              mes = mes + 1;
              this.aportacionesFilas.push(mes);
            }
            break; // mensual
          case 2:
            let bimestre = 0;
            for (let z = 0; z < this.aportaciones.cantidadAportacion; z++) {
              bimestre = bimestre + 2;
              this.aportacionesFilas.push(bimestre);
            }
            break; // bimestral
          case 3:
            let semestre = 0;
            for (let z = 0; z < this.aportaciones.cantidadAportacion; z++) {
              semestre = semestre + 6;
              this.aportacionesFilas.push(semestre);
            }
            break; // semestral
          case 4:
            let anual = 0;
            for (let z = 0; z < this.aportaciones.cantidadAportacion; z++) {
              anual = anual + 12;
              this.aportacionesFilas.push(anual);
            }
            break; // anual
        }
        // console.log( 'IndicesAMarcar', this.aportacionesFilas );
      }
      
      this.$vs.loading({
          container: '#cotizacion',
          scale: 0.6
        })  
      
      if (origen == 'TablaAmortizacion2') {            
        this.banderaTabla1 = false;    
        this.labelOrigen = origen;       
        this.labelPDF = 'TABLA DE AMORTIZACION 2'                 
        this.id_variante = item.DobleTablaAmortizacion; 
        this.dameVariante()        
        this.callsaveNewEventAmortizacion();
      }else if (origen == 'TablaAmortizacion1') {        
        this.banderaTabla1 = false;
        this.labelOrigen = origen        
        this.item.Origen = 'tablaAmortizacion1'        
        this.labelPDF = 'TABLA DE AMORTIZACION 1'
        this.updateCaso(this.caso.destino, this.caso.bancos)     
        this.callsaveNewEventAmortizacion();                 
      }else if(origen == 'cotiza'){
        if(item.BancoId==33){          
          this.tablaAmorKredi(item) 
        }else{                       
          this.tablaAmortizacion(item) 
        }
        this.callsaveNewEventAmortizacion();
      } else{        
        
          this.id_variante = item.DobleTablaAmortizacion
          if (this.id_variante) {
            this.caso.variante = item.Variante            

              if(item.BancoId==33){
                this.dameVariante();
                this.tablaAmorKredi(item) 

              }else{                     
                
                this.dameVariante();  
                this.tablaAmortizacion(item) 
              } 
          }  
          else{
            if(item.BancoId==33){              
                this.tablaAmorKredi(item) 

              }else{       
                
                              
                this.tablaAmortizacion(item) 
          }
          this.callsaveNewEventAmortizacion();        
        }       
      }
    },

    async callsaveNewEventAmortizacion()
    {
            /**Comienza el registro de evento en la nueva cotizacion */
      var nombreNegocio='Hipotecario'
      let nombreU,VBrokerid
      let nombreC=JSON.parse(localStorage.getItem('userInfo')).Nombre
      if(nombreC == null){
        nombreU=''
      }
      else
      {
        nombreU=nombreC
      }
      let BrokerIdC=JSON.parse(localStorage.getItem('userInfo')).IdBrokerSupervisor
      let OrganizacionNombreS=JSON.parse(localStorage.getItem('userInfo')).OrganizacionNombre
      let ValorBien=this.caso.valorInmueble
      let MontoSol=this.caso.montoSolicitado
      let numLineaNegocio=this.PrecalificacionTipo
      if(numLineaNegocio==3)
      {
        nombreNegocio='Auto'
      }
      if(BrokerIdC == null){
        VBrokerid=0
      }
      else
      {
         VBrokerid=BrokerIdC
        
      }
      await this.SaveNewEventAmortizacion('InsertarMedicionEventos','tabla de amortización',nombreU,VBrokerid,OrganizacionNombreS,nombreNegocio,ValorBien,MontoSol);
    },
    async SaveNewEventAmortizacion(metodo,nameOrigen,nombreUs,NBrokerId,OrganizacionNombreS,LineaNeg,ValorBien,MontoSol)
    {
      
      
			var objRequestCount = {
				strApiKey: this.key,
				strMetodo: metodo,
        objEventos: {
                Origen: nameOrigen,
                nombreUsuario: nombreUs,
                IdBroker: NBrokerId,
                NombreOrganizacion: OrganizacionNombreS,
                valorBien:ValorBien,
                LineaNegocio:LineaNeg,
                MontoSolicitado:MontoSol
            },
			}
      await this.$axios.post('/',objRequestCount,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            console.log(response)
             
            }else {
            this.$vs.notify({
              title:'Ocurrio un error en guardar eventos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
            console.log(response)
          }
				}
			).catch(function (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
        
			})
    },

    getTotalProducto(item) {

			let self = this
			if (this.id > 0) {
				this.idCaso = this.id
			}
			let TablaPagosIon = ''
			let AmortizacionPdf = ''
			let AmortizacionJson = ''
			if (item.BancoId == 21) {
				TablaPagosIon = true
				AmortizacionPdf = item.PdfAmortizacion
				AmortizacionJson = item.JsonAmortizacion
			}
			let objRequestDameTablaPagos = {
				strApiKey: this.key,
				strMetodo: 'DameTablaPagos',
				objResultado: {
					Id: item.Id,
					caso: this.id,
					caso: this.idCaso,
					IonAmortizacion: TablaPagosIon,
					pdfIon: AmortizacionPdf,
					jsonIon: AmortizacionJson,
					plazo: item.Plazo,
					tasa: item.Tasa,
				},
			}

			this.$axios
				.post('/', objRequestDameTablaPagos, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
					},
				})
				.then((response) => {
					if (response.data.intEstatus != 0) {
						return this.$vs.notify({
							title: 'Ocurrio un error en DameTablaPagos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}

					this.pagoMensualTotal = 0
					this.itemsTable = response.data.objContenido
          
					for (var i = 0; i < this.itemsTable.length; i++) {            
						this.pagoMensualTotal = this.pagoMensualTotal + this.itemsTable[i].PagoMensual
					}

          this.mensualidadInicial = this.itemsTable[1].PagoMensual          

					this.activePrompt = true
					this.popupActive = false

					this.$vs.loading.close('#tabla > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		numberFormat(num) {
			if (num != null) {
				return num
					.toFixed(2)
					.toString()
					.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			}
		},
		sendEmail(variant) {      

			const dataProducto = {
				mensualidadpromedio: variant.MensualidadPromedio,
				tasa: variant.Tasa,
				cat: variant.Cat,
				ingresominimo: variant.IngresoMinimo,
				pagototal: variant.PagoTotal,
				aforo: variant.Aforo,
				mensualidadinicial: variant.MensualidadInicial,
				gastosiniciales: variant.GastosIniciales,
				enganche: variant.Enganche,
				producto: variant.Producto,
				plazo: variant.Plazo,
			}

			let self = this
			var objEmail = {
				to: this.solicitante,
				case: this.id,
				token: this.key,
				variant: variant.Id,
				variant_name: variant.Producto,
				bank: variant.Banco,
				amortizacionIon: variant.JsonAmortizacion,
				dataProducto: variant.BancoId === 21 && dataProducto,
        variante: variant.Variante,
			}

			this.$vs.notify({
				title: 'Enviando Correo',
				position: 'top-right',
			})

			// axios.post('https://cotizador.socasesores.com/emailComparaleProd/Simulation.php',objEmail,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
			axios
				.post('https://cotizador.socasesores.com/admin/formatos/emailComparaleProd/EmailAmortizacionPDF.php', objEmail, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {
					this.$vs.notify({
						title: 'Se envió correctamente el correo electrónico a',
						text: this.solicitante,
						position: 'top-right',
					})
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
    descargarPDF(variant, varianteSecundaria){       
            
      let GastosInicialesVariante = 0;
      let GastosInicialesVarianteSecundaria = 0;
      if (variant.Programa == 4) {
        GastosInicialesVariante = variant.GastosInicialesCofinavit
      }
      else
      GastosInicialesVariante = variant.GastosIniciales
      
      if (varianteSecundaria.Programa == 4) {
        GastosInicialesVarianteSecundaria = varianteSecundaria.GastosInicialesCofinavit
      }
      else
        GastosInicialesVarianteSecundaria = varianteSecundaria.GastosIniciales

        const dataProducto = {
        mensualidadpromedio: variant.MensualidadPromedio,
        tasa: variant.Tasa,
        cat: variant.Cat,
        ingresominimo: variant.IngresoMinimo,
        pagototal: variant.PagoTotal,
        aforo: variant.Aforo,
        mensualidadinicial: variant.MensualidadInicial,
        gastosiniciales: variant.GastosIniciales,        
        enganche: variant.Enganche,
        producto: variant.Producto,
        plazo: variant.Plazo,
      }      

      let self=this
      var objEmail = {
      case: this.idCaso,
      token:this.key,
      variant:variant.Id,
      varianteSecundaria : this.labelPDF == 'TABLA DE AMORTIZACION 2' ? varianteSecundaria.Id : '',
        variant_name:variant.Producto,
        bank:variant.Banco,
        amortizacionIon: variant.JsonAmortizacion,
        plazo: variant.Plazo,
        dataProducto: (variant.BancoId === 21 ) && dataProducto,
        mensualidadpromedio: variant.MensualidadPromedio,
        tasa: variant.Tasa,
        tasaSecundaria :  this.labelPDF == 'TABLA DE AMORTIZACION 2' ? varianteSecundaria.Tasa: '',
        cat: variant.Cat,
        ingresominimo: variant.IngresoMinimo,
        pagototal: variant.PagoTotal,
        aforo: variant.Aforo,
        mensualidadinicial: variant.MensualidadInicial,
        gastosiniciales: GastosInicialesVariante,
        gastosInicialesSecundarios : variant.Id != varianteSecundaria.Id ? GastosInicialesVarianteSecundaria : 0,
        enganche: variant.Enganche,
        producto: variant.Producto,
        plazo: variant.Plazo,
        variante: variant.Variante,
        broker: this.broker,
        DobleTablaAmortizacion: variant.DobleTablaAmortizacion
      }

      if(this.PrecalificacionTipo == 3){
        location.href = 'https://cotizador.socasesores.com/emailComparaleProd/amortizaciones_automoviles.php?objeto=' + JSON.stringify(objEmail)
      }else
			  location.href = 'https://cotizador.socasesores.com/emailComparaleProd/DescargarPDF_nuevosFormatos.php?objeto=' + JSON.stringify(objEmail)
		},
		DescargarPDF_detalleProducto(item) {
			// return console.log('item :>> ', item)
      this.CambioEstatusV3()
      if (this.PrecalificacionTipo == 3) {
        location.href = 'https://cotizador.socasesores.com/emailComparaleProd/detalleProducto_automoviles.php?item=' + JSON.stringify(item) + '&idCaso=' + this.idCaso + '&key=' + this.key
      }else
			  location.href = 'https://cotizador.socasesores.com/emailComparaleProd/DescargarPDF_detalleProducto.php?item=' + JSON.stringify(item) + '&idCaso=' + this.idCaso + '&key=' + this.key
		},
		dameCaso() {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						let cotizacion = response.data.objContenido
						this.solicitante = cotizacion.Solicitante
						this.gastoNotarialPorcentaje = cotizacion.GastoNotarial
            this.caso.destino = cotizacion.Destino
            this.caso.plazos = cotizacion.Plazos
            this.caso.esquemas = cotizacion.Esquemas
            this.caso.bancos = cotizacion.ComparaBancos

            this.caso.valorInmueble = cotizacion.ValorInmueble
            this.caso.gastosNotarial = cotizacion.GastoNotarial
            this.caso.montoSolicitado = cotizacion.MontoSolicitado
            this.PrecalificacionTipo = cotizacion.PrecalificacionTipo
            if (this.PrecalificacionTipo == 3){
              this.montoSolicitadoAuto = this.caso.montoSolicitado              
              this.DameCasoAuto() 
            }

            if (this.caso.destino == 20) { // 20	ADQUISICION DE TERRENO Y CONSTRUCCION
              this.caso.ValorProyecto = cotizacion.ValorProyecto
              this.caso.ValorTerreno =  cotizacion.ValorTerreno
            }        
                        
            if (response.data.objContenido.Broker != "demoSoc@socasesores.com") {
              this.isCasoCompleto = true
            }

            // para marcar las filas de aportaciones en la tabla
            if (cotizacion.TieneAportacion == "Si") {
              this.aportaciones.tieneAportacion = cotizacion.TieneAportacion;
              this.aportaciones.cantidadAportacion = cotizacion.CantidadAportaciones;
              this.aportaciones.plazoAportacion = cotizacion.PlazoAportacion;
              // console.log( 'Si tiene aportaciones' );
              // console.log( this.aportaciones );
            }
            
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    DameCasoAuto(){
      let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCasoAuto',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
            let vctPrecalificacionAuto = ''

            vctPrecalificacionAuto = response.data.objContenido
            this.anioVehiculo = vctPrecalificacionAuto.Anio
            this.engancheAuto = parseInt(vctPrecalificacionAuto.Enganche)


          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
        this.broker=JSON.parse(localStorage.getItem('userInfo')).EMail;
      }
    },
    clearFields () {
      
      this.banderaTabla1 = false;            
      this.item.Origen = ''
      this.labelPDF = ''
      /*this.updateCaso(this.caso.destino, this.caso.bancos)*/


      this.tablaDeAmortizacion = true;
      

      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
		exportToExcel() {
			import('@/vendor/Export2Excel').then((excel) => {
				let items = []
				for (let i = 0; i < this.itemsTable.length; i++) {
					let itemTabla = []
					itemTabla.Amortizacion = '$ ' + this.numberFormat(this.itemsTable[i].Amortizacion)
					itemTabla.Capital = '$ ' + this.numberFormat(this.itemsTable[i].Capital)
					itemTabla.GastosDiferidos = '$ ' + this.numberFormat(this.itemsTable[i].GastosDiferidos)
					itemTabla.Fecha = this.itemsTable[i].Fecha
					itemTabla.Interes = '$ ' + this.numberFormat(this.itemsTable[i].Interes)
					itemTabla.Mensualidad = '$ ' + this.numberFormat(this.itemsTable[i].Mensualidad)
					itemTabla.Mes = this.itemsTable[i].Mes
					itemTabla.PagoMensual = '$ ' + this.numberFormat(this.itemsTable[i].PagoMensual)
					itemTabla.Tasa = this.numberFormat(this.itemsTable[i].Tasa) + '%'

					items.push(itemTabla)
				}

				const list = items
				const data = this.formatJson(this.headerVal, list)
				excel.export_json_to_excel({
					header: this.headerTitle,
					data,
					filename: this.fileName,
					autoWidth: this.cellAutoWidth,
					bookType: this.selectedFormat,
				})
				this.clearFields()
			})
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) =>
				filterVal.map((j) => {
					return v[j]
				})
			)
		},
		DescargaPdfIon(urlIon) {
			let self = this

			var objPdf = {
				url: urlIon,
			}
			axios
				.post('https://cotizador.socasesores.com/DescargaPdfIon.php', objPdf, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {
					location.href = 'https://cotizador.socasesores.com/uploads/AmortizacionIon/AmortizacionIon.php?objUrl=' + JSON.stringify(urlIon)
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
    tablaAmortizacionKrediDev(item) {
      let objRequestRegistraSolicitante = {
    strApiKey: this.key,
    strMetodo: 'MethodoKrediPagoMensual',
      }

      this.$axios
    .post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
    .then((response) => {

    this.pagoMensualTotal = 0
     this.itemsTable = JSON.parse(response.data.objRespuesta)
     this.pagoMensualTotal = this.itemsTable.data.attributes.additional_information.totals.pago_mensual

     this.activePrompt = true
     this.popupActive = false

     this.$vs.loading.close('#tabla > .con-vs-loading')
    })
    .catch(function (error) {
     self.$vs.notify({
      title: 'Ocurrio un errros en api KREDI',
      text: error,
      color: 'danger',
      position: 'top-right',
     })
    })

    },
		tablaAmortizacionKredi(item) {
			let respuestaKrediAmortizacion = ''

			this.$vs.loading({
				container: '#tabla',
				scale: 0.6,
			})

			var product_application = {
				idTablaAmortizacion: item.JsonAmortizacion,
			}
			this.$axios
				.post('https://cotizador.socasesores.com/ApiION/MethodoKrediPagoMensual.php', product_application, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {
					this.pagoMensualTotal = 0
					this.itemsTable = JSON.parse(response.data.objRespuesta)
					this.pagoMensualTotal = this.itemsTable.data.attributes.additional_information.totals.pago_mensual                 

					this.activePrompt = true
					this.popupActive = false

					this.$vs.loading.close('#tabla > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un errros en api KREDI',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})


    },
    tablaAmorKrediDev(item){
      
    let objRequestRegistraSolicitante = {
    strApiKey: this.key,
    strMetodo: 'MethodoKrediAmortizacion',
      }

      this.$axios
    .post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
    .then((response) => {

          // separacion seguros y otros
					this.sumaSegVida = 0;
					this.sumaSegDanio = 0;
					this.sumaSegDesempleo = 0;
					this.sumaSegComision = 0;

					this.interesesTotales = 0
					this.mensualidadTotal = 0
					this.amortizacionTotal = 0
					this.gastosTotales = 0
					this.pagoMensualTotal = 0
					this.mesesTotales = 0
					this.montoSolicitado = 0
					this.tasa = 0
					this.itemsTable = response.data.objContenido          
					this.popupActive, (this.popupActiveExc = true)
					for (var i = 0; i < this.itemsTable.length; i++) {

            // si es SCOTIABANK ADQUISICIÓN DE VIVIENDA FOVISSSTE PARA TODOS id 163 no se suma mes cero
            if (this.itemsTable[i].Mes != 0) {
              this.interesesTotales = this.interesesTotales + this.itemsTable[i].Interes
              this.mensualidadTotal = this.mensualidadTotal + this.itemsTable[i].Mensualidad
              this.amortizacionTotal = this.amortizacionTotal + this.itemsTable[i].Amortizacion
              this.gastosTotales = this.gastosTotales + this.itemsTable[i].GastosDiferidos
              this.pagoMensualTotal = this.pagoMensualTotal + this.itemsTable[i].PagoMensual
            }
						this.tasa = this.itemsTable[i].Tasa
						this.mesesTotales = this.itemsTable[i].Mes
						this.montoSolicitado = this.itemsTable[i].Capital
/*  
						// seguros y otros
						let vctResultSegurosOtros = this.searchSegurosOtros(this.itemsTable[i].vctGastosDiferidos, item, this.itemsTable[i]);
            // si es SCOTIABANK ADQUISICIÓN DE VIVIENDA FOVISSSTE PARA TODOS id 163 no se suma mes cero
            if (this.itemsTable[i].Mes != 0) {
              this.sumaSegVida = this.sumaSegVida + vctResultSegurosOtros[0];
              this.sumaSegDanio = this.sumaSegDanio + vctResultSegurosOtros[1];
              this.sumaSegDesempleo = this.sumaSegDesempleo + vctResultSegurosOtros[2];
              this.sumaSegComision = this.sumaSegComision + vctResultSegurosOtros[3];
            }

						// inyectar nuevas propiedades en cada elemento del array
						this.itemsTable[i].SegVida = vctResultSegurosOtros[0];
						this.itemsTable[i].SegDanio = vctResultSegurosOtros[1];
						this.itemsTable[i].SegDesempleo = vctResultSegurosOtros[2];
						this.itemsTable[i].SegComision = vctResultSegurosOtros[3];

            if (this.itemsTable[i].Mes == 0) { // cambia el pago mensual mes cero
              this.itemsTable[i].PagoMensual = this.itemsTable[i].Interes + vctResultSegurosOtros[0] + vctResultSegurosOtros[1];
            }*/

					}

					this.$vs.loading.close('#cotizacion > .con-vs-loading')
    })
    .catch(function (error) {
     self.$vs.notify({
      title: 'Ocurrio un errros en api KREDI',
      text: error,
      color: 'danger',
      position: 'top-right',
     })
    })

    },
    tablaAmorKredi(item){
      
      let self=this
      if(this.id>0){
        this.idCaso=this.id
      }

      /* this.$vs.loading({
          container: '#tabla',
          scale: 0.6
        })*/

        var product_application = {
				idTablaAmortizacion: item.JsonAmortizacion,
        plazoKredi: item.Plazo
			
			}

			/*this.$vs.loading({
				container: '#tabla',
				scale: 0.6,
			})*/

			var product_application = {
				idTablaAmortizacion: item.JsonAmortizacion,
				plazoKredi: item.Plazo,
			}
			this.$axios
				.post('https://cotizador.socasesores.com/ApiION/MethodoKredAmortizacion.php', product_application, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {
					//this.itemsTable = JSON.parse(response.data.objRespuesta)

					// separacion seguros y otros
					this.sumaSegVida = 0;
					this.sumaSegDanio = 0;
					this.sumaSegDesempleo = 0;
					this.sumaSegComision = 0;

					this.interesesTotales = 0
					this.mensualidadTotal = 0
					this.amortizacionTotal = 0
					this.gastosTotales = 0
					this.pagoMensualTotal = 0
					this.mesesTotales = 0
					this.montoSolicitado = 0
					this.tasa = 0
					this.itemsTable = response.data.objContenido
          console.log("tablaAmorKredi")
          console.log(this.itemsTable)
					this.popupActive, (this.popupActiveExc = true)
					for (var i = 0; i < this.itemsTable.length; i++) {

            // si es SCOTIABANK ADQUISICIÓN DE VIVIENDA FOVISSSTE PARA TODOS id 163 no se suma mes cero
            if (this.itemsTable[i].Mes != 0) {
              this.interesesTotales = this.interesesTotales + this.itemsTable[i].Interes
              this.mensualidadTotal = this.mensualidadTotal + this.itemsTable[i].Mensualidad
              this.amortizacionTotal = this.amortizacionTotal + this.itemsTable[i].Amortizacion
              this.gastosTotales = this.gastosTotales + this.itemsTable[i].GastosDiferidos
              this.pagoMensualTotal = this.pagoMensualTotal + this.itemsTable[i].PagoMensual
            }
						this.tasa = this.itemsTable[i].Tasa
						this.mesesTotales = this.itemsTable[i].Mes
						this.montoSolicitado = this.itemsTable[i].Capital
/*  
						// seguros y otros
						let vctResultSegurosOtros = this.searchSegurosOtros(this.itemsTable[i].vctGastosDiferidos, item, this.itemsTable[i]);
            // si es SCOTIABANK ADQUISICIÓN DE VIVIENDA FOVISSSTE PARA TODOS id 163 no se suma mes cero
            if (this.itemsTable[i].Mes != 0) {
              this.sumaSegVida = this.sumaSegVida + vctResultSegurosOtros[0];
              this.sumaSegDanio = this.sumaSegDanio + vctResultSegurosOtros[1];
              this.sumaSegDesempleo = this.sumaSegDesempleo + vctResultSegurosOtros[2];
              this.sumaSegComision = this.sumaSegComision + vctResultSegurosOtros[3];
            }

						// inyectar nuevas propiedades en cada elemento del array
						this.itemsTable[i].SegVida = vctResultSegurosOtros[0];
						this.itemsTable[i].SegDanio = vctResultSegurosOtros[1];
						this.itemsTable[i].SegDesempleo = vctResultSegurosOtros[2];
						this.itemsTable[i].SegComision = vctResultSegurosOtros[3];

            if (this.itemsTable[i].Mes == 0) { // cambia el pago mensual mes cero
              this.itemsTable[i].PagoMensual = this.itemsTable[i].Interes + vctResultSegurosOtros[0] + vctResultSegurosOtros[1];
            }*/

					}

					this.$vs.loading.close('#cotizacion > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un errros en api KREDI',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},		
    tablaAmortizacion(item){  
      
      let self=this
      this.itemMinitraciones = []
      this.mesMinistracion = 1
      this.showMinistraciones = item.MinistracionesFormula
      if(this.id>0){
        this.idCaso=this.id
      }
      let TablaPagosIon= ''
      let AmortizacionPdf=''
      let AmortizacionJson=''
      if(item.BancoId==21){
        TablaPagosIon=true;
        AmortizacionPdf= item.PdfAmortizacion
        AmortizacionJson= item.JsonAmortizacion
      }
      /*if (!item.Origen) {        
        this.$vs.loading({
          container: '#tabla',
          scale: 0.6
        })  
      }*/
      
      let objRequestDameTablaPagos = {
        strApiKey: this.key,
        strMetodo: 'DameTablaPagos',
        objResultado: {
          Id:item.Id,
          caso:this.id,
          caso:this.idCaso,
          IonAmortizacion:TablaPagosIon,
          pdfIon:AmortizacionPdf,
          jsonIon:AmortizacionJson,
          plazo: item.Plazo,
          tasa: item.Tasa
        }
      }
      this.$axios.post('/',objRequestDameTablaPagos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus != 0 ){
            this.$vs.notify({
              title:'Ocurrio un error en DameTablaPagos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }else {
            //  document.getElementById('labelAmortizacion').style.display = 'block';
            if(item.BancoId==21){
               //this.DescargaPdfIon(response.data.objContenido)
               //this.$vs.loading.close('#tabla > .con-vs-loading')           

            this.interesesTotales=0
            this.mensualidadTotal=0
            this.amortizacionTotal=0
            this.gastosTotales=0
            this.pagoMensualTotal=0
            this.mesesTotales=0
            this.montoSolicitado=0
            this.tasa=0
            this.itemsTable= response.data.objContenido
            this.popupActive, this.popupActiveExc = true
            for (var i = 0; i < this.itemsTable.length; i++) {
              // si es SCOTIABANK ADQUISICIÓN DE VIVIENDA FOVISSSTE PARA TODOS id 163 no se suma mes cero
              if (this.itemsTable[i].Mes != 0) {
                this.interesesTotales =this.interesesTotales +this.itemsTable[i].Interes
                this.mensualidadTotal =this.mensualidadTotal +this.itemsTable[i].Mensualidad
                this.amortizacionTotal =this.amortizacionTotal +this.itemsTable[i].Amortizacion
                this.gastosTotales =this.gastosTotales +this.itemsTable[i].GastosDiferidos
                this.pagoMensualTotal =this.pagoMensualTotal +this.itemsTable[i].PagoMensual
              }
              this.tasa=this.itemsTable[i].Tasa
              this.mesesTotales=this.itemsTable[i].Mes
              this.montoSolicitado=this.itemsTable[i].Capital

				// seguros y otros
				let vctResultSegurosOtros = this.searchSegurosOtros(this.itemsTable[i].vctGastosDiferidos, item, this.itemsTable[i]);
        // si es SCOTIABANK ADQUISICIÓN DE VIVIENDA FOVISSSTE PARA TODOS id 163 no se suma mes cero
        if (this.itemsTable[i].Mes != 0) {
          this.sumaSegVida = this.sumaSegVida + vctResultSegurosOtros[0];
          this.sumaSegDanio = this.sumaSegDanio + vctResultSegurosOtros[1];
          this.sumaSegDesempleo = this.sumaSegDesempleo + vctResultSegurosOtros[2];
          this.sumaSegComision = this.sumaSegComision + vctResultSegurosOtros[3];
        }

				// inyectar nuevas propiedades en cada elemento del array
				this.itemsTable[i].SegVida = vctResultSegurosOtros[0];
				this.itemsTable[i].SegDanio = vctResultSegurosOtros[1];
				this.itemsTable[i].SegDesempleo = vctResultSegurosOtros[2];
				this.itemsTable[i].SegComision = vctResultSegurosOtros[3];

            if (this.itemsTable[i].Mes == 0) { // cambia el pago mensual mes cero
              this.itemsTable[i].PagoMensual = this.itemsTable[i].Interes + vctResultSegurosOtros[0] + vctResultSegurosOtros[1];
            }

            }

						if (!item.Origen) {
              this.$vs.loading.close('#tabla > .con-vs-loading')              
            }

            } else{

				// separacion seguros y otros
				this.sumaSegVida = 0;
				this.sumaSegDanio = 0;
				this.sumaSegDesempleo = 0;
				this.sumaSegComision = 0;

            this.interesesTotales=0
            this.mensualidadTotal=0
            this.amortizacionTotal=0
            this.gastosTotales=0
            this.pagoMensualTotal=0
            this.mesesTotales=0
            this.montoSolicitado=0
            this.tasa=0
            this.itemsTable= response.data.objContenido

			// console.log( this.itemsTable );

            this.popupActive, this.popupActiveExc = true
            for (var i = 0; i < this.itemsTable.length; i++) {
              // si es SCOTIABANK ADQUISICIÓN DE VIVIENDA FOVISSSTE PARA TODOS id 163 no se suma mes cero
              if (this.itemsTable[i].Mes != 0) {
                this.interesesTotales =this.interesesTotales +this.itemsTable[i].Interes
                this.mensualidadTotal =this.mensualidadTotal +this.itemsTable[i].Mensualidad
                this.amortizacionTotal =this.amortizacionTotal +this.itemsTable[i].Amortizacion
                this.gastosTotales =this.gastosTotales +this.itemsTable[i].GastosDiferidos
                this.pagoMensualTotal =this.pagoMensualTotal +this.itemsTable[i].PagoMensual
              }

              this.tasa=this.itemsTable[i].Tasa
              this.mesesTotales=this.itemsTable[i].Mes
              this.montoSolicitado=this.itemsTable[i].Capital

              	// seguros y otros
                let vctResultSegurosOtros = this.searchSegurosOtros(this.itemsTable[i].vctGastosDiferidos, item, this.itemsTable[i]);
                // si es SCOTIABANK ADQUISICIÓN DE VIVIENDA FOVISSSTE PARA TODOS id 163 no se suma mes cero
                if (this.itemsTable[i].Mes != 0) {
                  this.sumaSegVida = this.sumaSegVida + vctResultSegurosOtros[0];
                  this.sumaSegDanio = this.sumaSegDanio + vctResultSegurosOtros[1];
                  this.sumaSegDesempleo = this.sumaSegDesempleo + vctResultSegurosOtros[2];
                  this.sumaSegComision = this.sumaSegComision + vctResultSegurosOtros[3];
                }

                // inyectar nuevas propiedades en cada elemento del array
                this.itemsTable[i].SegVida = vctResultSegurosOtros[0];
                this.itemsTable[i].SegDanio = vctResultSegurosOtros[1];
                this.itemsTable[i].SegDesempleo = vctResultSegurosOtros[2];
                this.itemsTable[i].SegComision = vctResultSegurosOtros[3];

                if (this.itemsTable[i].Mes == 0) { // cambia el pago mensual mes cero
                  this.itemsTable[i].PagoMensual = this.itemsTable[i].Interes + vctResultSegurosOtros[0] + vctResultSegurosOtros[1];
                }

              //Ministraciones
              if (this.itemsTable[i].Ministraciones != null) {                  
                this.itemMinitraciones.push(this.itemsTable[i])
                this.itemMinitraciones[i].Mes = this.mesMinistracion;
                this.mesMinistracion += 1                

              }
              
            }            
            if (this.showMinistraciones > 0) {
                for (let index = 0; index < this.plazoMinistracion; index++) {                              
                  this.itemsTable.splice(0, 1);
                } 
            }            

            }            
            this.$vs.loading.close('#cotizacion > .con-vs-loading')
            
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
     DameCredenciales(){
      let self=this
      let objRequestDameCredenciales = {
        strApiKey: this.key,
        strMetodo: 'DameCredenciales',
        objBroker: {
          ApiKey: this.key
        }
      }
      this.$axios.post('/',objRequestDameCredenciales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          this.SocGuid = response.data.objContenido.SocGuid

        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
     CambioEstatusV3(){
      let self=this

       let objRequestCotizaCaso = {
      /////  AcreditModel: {
          PersonalMail: this.solicitante,
          Status:'Asesoría',
          EmployeeId:this.SocGuid,

      //////  },
      }

      this.$axios.put('https://apivalidacionriesgos.sisec.mx/api/external/update-statusaccredited',objRequestCotizaCaso,{headers : this.header})
        .then(
          response => {

           /// alert('REgistro exitoso')
           
            
          }
        ).catch(function (error) {
          if(error.response.data.Message == "The CellPhone already exists"){
            mostrar = "El telefono ya se encuentra registrado intente con otro"
          }else if(error.response.data.Message == "The email already exists"){
            mostrar = "El correo elecronico ya se encuentra registrado intente con otro"
          }else{
            mostrar = error
          }
          
          self.$vs.notify({
            title:'Ocurrio un error en SISEC',
            text:mostrar,
            color:'danger',
            position:'top-right'
          })
        })


    },
   
    dameVariante(){    
      
      /*this.$vs.loading({
        container: '#cotizacion',
        scale: 0.6,
       })*/

      //let self = this        
        let objRequestVariante = {
          strApiKey: this.key,
          strMetodo: 'dameVariante',
          objVariante: {
            Variante: this.id_variante,
            //GastoNotarial: this.caso.gastosNotarial
          },
        }
        this.$axios
          .post('/', objRequestVariante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then((response) => {
            if (response.data.intEstatus == 0) {
              var dataVariante = response.data.objContenido
              
              this.caso2.destino = dataVariante[0].Destino
              this.caso2.bancoId = dataVariante[0].BancoId 
              /*if (!this.gastosIniciales) {                
                this.gastosIniciales = dataVariante[0].GastosInicialesAmortizacion2              
                alert("gastos iniciales es vacio y asigno "+ this.gastosIniciales)
              }       */       
              this.cambiaBancos()                
              
              //alert("destino tabla amortizacio=  "+ this.caso2.destino)

            }else {
      this.$vs.notify({
       title: 'Ocurrio un error en Cotiza',
       text: response.data.strError,
       color: 'danger',
       position: 'top-right',
      })
     }
     // this.$vs.loading.close('#cotizacion > .con-vs-loading')
    })
    .catch(function (error) {
     self.$vs.notify({
      title: 'Ocurrio un error de sistema',
      text: error,
      color: 'danger',
      position: 'top-right',
     })
    })       
    },
    cambiaBancos() {

      let self = this
      let objRequestDamePlazos = {
        strApiKey: this.key,
        strMetodo: 'ListaBancosPlazos',
        objCaso: {
          Destino: this.caso2.destino,
          Esquema: this.caso.esquemas,
          Plazos: this.caso.plazos,
        },
      }
      this.$axios
        .post('/', objRequestDamePlazos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then((response) => {
          if (response.data.intEstatus == 0) {
            this.caso2.bancos=[]
          /*if(this.caso.plazos.length==1){            
              if(this.caso.plazos[0]=='25'){
              

                //this.caso2.bancos.push({ label: 'BANORTE', value: 4 })
                //this.caso2.bancos.push({ label: 'HSBC', value: 6 })
                this.caso2.bancos.push(4,6);
                this.bancos.push(4,6);
              
                //this.bancos.push({ label: 'BANORTE', value: 4 })
                //this.bancos.push({ label: 'HSBC', value: 6 })
                
            }else{              
                let bancos = response.data.objContenido
                for (var i = 0; i < bancos.length; i++) {
                  //this.bancos.push({ label: bancos[i].Nombre, value: bancos[i].Id })
                  this.bancos.push(bancos[i].Id);
                }
                  //this.bancos.push({ label: 'KREDI', value: 33 })
                    //this.caso.bancos = this.bancosEjecutivo //cambio bancos
                   //// this.bancos.push(33)
                      this.caso2.bancos = this.bancos 
          }

          }else{ */           
                let bancos = response.data.objContenido
                for (var i = 0; i < bancos.length; i++) {
                  //this.bancos.push({ label: bancos[i].Nombre, value: bancos[i].Id })
                  if (bancos[i].Id == this.caso2.bancoId) {
                    this.bancos.push(bancos[i].Id) 
                  }                  
                }
                  //this.bancos.push({ label: 'KREDI', value: 33 })
                  //this.bancos.push(33);
                      
                  this.caso2.bancos = this.bancos
        // }
          //console.log(JSON.stringify(this.caso2.bancos))
          this.updateCaso(this.caso2.destino, this.caso2.bancos)
          } else if(response.data.intEstatus == 1){
          this.caso.bancos = [];
      }else {
            this.$vs.notify({
              title: 'Ocurrio un error en ListaFinancieras',
              text: response.data.strError,
              color: 'danger',
              position: 'top-right',
            })
          }
        })
        .catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error en DamePlazos',
            text: error,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    
    updateCaso(destino, bancos){    
        
        /*if (this.labelOrigen == 'TablaAmortizacion1') {
            this.$vs.loading({
            container: '#cotizacion',
            scale: 0.6,
          })
        }        */    
        
        let self = this
        let objRequestRegistraCaso1 = {
					strApiKey: this.key,
					strMetodo: 'updateCasoAmortizacion',
					objCaso: {
						Id: parseInt(this.id),
						Destino: destino,
						Bancos: bancos.toString(),
					}
        }
          this.$axios
          .post('/', objRequestRegistraCaso1, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then((response) => {
            if (response.data.intEstatus == 0) {
             /* if (origen == 'TablaAmortizacion1') {
                if(item.BancoId==33){
                  this.tablaAmorKredi(item) 

                }else{
                  this.tablaAmortizacion(item) 
                }
              }*/
              if (this.banderaTabla1) {
                console.log(response.data.objContenido)                
              }else{
                //alert("entre else cotiza")
                this.cotiza() 
              }
            }else {
						this.$vs.notify({
							title: 'Ocurrio un error en updateCasoAmortizacion',
							text: response.data.strError,
							color: 'danger', 
							position: 'top-right',
						})						
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
    },
    cotiza() {

      
      //alert("metodo cotiza "+ this.labelOrigen)
        let self = this
        this.items = []
        let objRequestCotizaCaso = {
          strApiKey: this.key,
          strMetodo: 'Cotiza',
          objCaso: {
            Id: this.id,
            //GastoNotarial: this.caso.gastosNotarial
          },
        }
        this.$axios
          .post('/', objRequestCotizaCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then((response) => {
            if (response.data.intEstatus == 0) {
              
              var Items = response.data.objContenido
              
                for (var i = 0; i < Items.length; i++) {     
                  
                  									
										


                  




                  if (this.labelOrigen == 'TablaAmortizacion2') {
                    if (Items[i].Variante == this.id_variante) {
                      
                      this.ans = Items[i]                         
                      if( Items[i].Variante==4 || Items[i].Variante==6 || Items[i].Variante==14 || Items[i].Variante==21 || Items[i].Variante==22 || Items[i].Variante==26 ||  Items[i].Variante==30 || Items[i].Variante==31 || Items[i].Variante==35 || Items[i].Variante==37 || Items[i].Variante==40 || Items[i].Variante==51 ||  Items[i].Variante==65 || Items[i].Variante==133 || Items[i].Variante==138 || Items[i].Variante==171 || Items[i].Variante==176 || Items[i].Variante==184 ||  Items[i].Variante==194 || Items[i].Variante==197 || Items[i].Variante==198 || Items[i].Variante==9 || Items[i].Variante==71 || Items[i].Variante==72 ||  Items[i].Variante==102 || Items[i].Variante==103 || Items[i].Variante==154 || Items[i].Variante==75 || Items[i].Variante==129 || Items[i].Variante==131 ||  Items[i].Variante==139 || Items[i].Variante==175 || Items[i].Variante==11 || Items[i].Variante==17 || Items[i].Variante==23 || Items[i].Variante==24 ||  Items[i].Variante==39 || Items[i].Variante==96 || Items[i].Variante==109 || Items[i].Variante==144 || Items[i].Variante==182 || Items[i].Variante==183 ||  Items[i].Variante==189 || Items[i].Variante==190 || Items[i].Variante==193 || Items[i].Variante==10 || Items[i].Variante==66 || Items[i].Variante==67 ||  Items[i].Variante==85 || Items[i].Variante==97 || Items[i].Variante==98 || Items[i].Variante==168 || Items[i].Variante==82 || Items[i].Variante==132 ||  Items[i].Variante==7 || Items[i].Variante==69 || Items[i].Variante==73 || Items[i].Variante==81 || Items[i].Variante==84 || Items[i].Variante==104 ||  Items[i].Variante== 105|| Items[i].Variante==166 || Items[i].Variante==32 || Items[i].Variante==202 || Items[i].Variante==203 || Items[i].Variante==204 || Items[i].Variante==205 || Items[i].Variante==206 || Items[i].Variante==208 || Items[i].Variante==211 || Items[i].Variante==212){
												Items[i].Enganche= 0
                        }
                      
                      /////validara si son los productos 4 y 171 ya q tienen promocion de gastos en 0
                        if(Items[i].Variante==4 || Items[i].Variante==171){
                          
                          Items[i].GastosNotariales= 0
                          Items[i].GastosIniciales= Items[i].Avaluo + Items[i].ComisionApertura + Items[i].GastosNotariales
                        }else if(Items[i].Variante==182 || Items[i].Variante==183 || Items[i].Variante==190 || Items[i].Variante==32 || Items[i].Variante==189 || Items[i].Variante==11 || Items[i].Variante==39 || Items[i].Variante==17 || Items[i].Variante==23 || Items[i].Variante==24 || Items[i].Variante==96 || Items[i].Variante==109 || Items[i].Variante==144 || Items[i].Variante==167 || Items[i].Variante==168 || Items[i].Variante==170 ||  Items[i].Variante==66 || Items[i].Variante==67 || Items[i].Variante==71 || Items[i].Variante==72 || Items[i].Variante==85 || Items[i].Variante==9 || Items[i].Variante==10 || Items[i].Variante==97 || Items[i].Variante==98 || Items[i].Variante==102 || Items[i].Variante==103){

                          Items[i].GastosNotariales= ( (this.caso.gastosNotarial * Items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.montoSolicitado.replace(/,/g,""))
                    
                          Items[i].GastosIniciales= Items[i].Avaluo + Items[i].ComisionApertura + Items[i].GastosNotariales
                        }else if(Items[i].Variante==19 || Items[i].Variante==20 || Items[i].Variante==25  || Items[i].Variante==163){////Scotiabank tiene promo de 20% de descuento
                          
                          Items[i].GastosNotariales= (( (this.caso.gastosNotarial * Items[i].FactorDescuentoGastosNotariales) / 100) * this.caso.valorInmueble) * 0.8
                          Items[i].GastosIniciales= Items[i].Avaluo + Items[i].ComisionApertura + Items[i].GastosNotariales

                        // TODO: factor porcentual para santander (variantes solo existen en prod 191, 192)
                        // TODO: para pruebas ( 156, 157 )
                        } else if(Items[i].Variante == 191 || Items[i].Variante == 192) {  //PROD
                        //}else if(Items[i].Variante == 174 || Items[i].Variante == 175) {  // dev
                        
                          Items[i].GastosNotariales= ( (this.caso.gastosNotarial * Items[i].FactorDescuentoGastosNotariales) / 100) * this.caso.valorInmueble

                          Items[i].GastosIniciales = Items[i].Avaluo + Items[i].ComisionApertura + Items[i].GastosNotariales + calculaFactorSantander(Items[i].Aforo, (Items[i].Plazo / 12), Items[i].Monto );

                        } else{		                      
                          Items[i].GastosNotariales= ( (this.caso.gastosNotarial * Items[i].FactorDescuentoGastosNotariales) / 100) * this.caso.valorInmueble
                          Items[i].GastosIniciales= Items[i].Avaluo + Items[i].ComisionApertura + Items[i].GastosNotariales
                          
                        }
                      this.tablaAmortizacionBancos(this.ans,'cotiza');                         
                    }
                  }else if(this.labelOrigen == ''){
                    this.banderaTabla1 = true;
                    if (Items[i].Variante == this.id_variante) {

                      if( Items[i].Variante==4 || Items[i].Variante==6 || Items[i].Variante==14 || Items[i].Variante==21 || Items[i].Variante==22 || Items[i].Variante==26 ||  Items[i].Variante==30 || Items[i].Variante==31 || Items[i].Variante==35 || Items[i].Variante==37 || Items[i].Variante==40 || Items[i].Variante==51 ||  Items[i].Variante==65 || Items[i].Variante==133 || Items[i].Variante==138 || Items[i].Variante==171 || Items[i].Variante==176 || Items[i].Variante==184 ||  Items[i].Variante==194 || Items[i].Variante==197 || Items[i].Variante==198 || Items[i].Variante==9 || Items[i].Variante==71 || Items[i].Variante==72 ||  Items[i].Variante==102 || Items[i].Variante==103 || Items[i].Variante==154 || Items[i].Variante==75 || Items[i].Variante==129 || Items[i].Variante==131 ||  Items[i].Variante==139 || Items[i].Variante==175 || Items[i].Variante==11 || Items[i].Variante==17 || Items[i].Variante==23 || Items[i].Variante==24 ||  Items[i].Variante==39 || Items[i].Variante==96 || Items[i].Variante==109 || Items[i].Variante==144 || Items[i].Variante==182 || Items[i].Variante==183 ||  Items[i].Variante==189 || Items[i].Variante==190 || Items[i].Variante==193 || Items[i].Variante==10 || Items[i].Variante==66 || Items[i].Variante==67 ||  Items[i].Variante==85 || Items[i].Variante==97 || Items[i].Variante==98 || Items[i].Variante==168 || Items[i].Variante==82 || Items[i].Variante==132 ||  Items[i].Variante==7 || Items[i].Variante==69 || Items[i].Variante==73 || Items[i].Variante==81 || Items[i].Variante==84 || Items[i].Variante==104 ||  Items[i].Variante== 105|| Items[i].Variante==166 || Items[i].Variante==32 || Items[i].Variante==202 || Items[i].Variante==203 || Items[i].Variante==204 || Items[i].Variante==205 || Items[i].Variante==206 || Items[i].Variante==208 || Items[i].Variante==211 || Items[i].Variante==212){
												Items[i].Enganche= 0
                      }
                    
                    /////validara si son los productos 4 y 171 ya q tienen promocion de gastos en 0
                      if(Items[i].Variante==4 || Items[i].Variante==171){
                        
                        Items[i].GastosNotariales= 0
                        Items[i].GastosIniciales= Items[i].Avaluo + Items[i].ComisionApertura + Items[i].GastosNotariales
                      }else if(Items[i].Variante==182 || Items[i].Variante==183 || Items[i].Variante==190 || Items[i].Variante==32 || Items[i].Variante==189 || Items[i].Variante==11 || Items[i].Variante==39 || Items[i].Variante==17 || Items[i].Variante==23 || Items[i].Variante==24 || Items[i].Variante==96 || Items[i].Variante==109 || Items[i].Variante==144 || Items[i].Variante==167 || Items[i].Variante==168 || Items[i].Variante==170 ||  Items[i].Variante==66 || Items[i].Variante==67 || Items[i].Variante==71 || Items[i].Variante==72 || Items[i].Variante==85 || Items[i].Variante==9 || Items[i].Variante==10 || Items[i].Variante==97 || Items[i].Variante==98 || Items[i].Variante==102 || Items[i].Variante==103){

                        Items[i].GastosNotariales= ( (this.caso.gastosNotarial * Items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.montoSolicitado.replace(/,/g,""))
                  
                        Items[i].GastosIniciales= Items[i].Avaluo + Items[i].ComisionApertura + Items[i].GastosNotariales
                      }else if(Items[i].Variante==19 || Items[i].Variante==20 || Items[i].Variante==25  || Items[i].Variante==163){////Scotiabank tiene promo de 20% de descuento
                        
                        Items[i].GastosNotariales= (( (this.caso.gastosNotarial * Items[i].FactorDescuentoGastosNotariales) / 100) * this.caso.valorInmueble) * 0.8
                        Items[i].GastosIniciales= Items[i].Avaluo + Items[i].ComisionApertura + Items[i].GastosNotariales

                      // TODO: factor porcentual para santander (variantes solo existen en prod 191, 192)
                      // TODO: para pruebas ( 156, 157 )
                      } else if(Items[i].Variante == 191 || Items[i].Variante == 192) {  //PROD
                      //}else if(Items[i].Variante == 174 || Items[i].Variante == 175) {  // dev
                      
                        Items[i].GastosNotariales= ( (this.caso.gastosNotarial * Items[i].FactorDescuentoGastosNotariales) / 100) * this.caso.valorInmueble

                        Items[i].GastosIniciales = Items[i].Avaluo + Items[i].ComisionApertura + Items[i].GastosNotariales + calculaFactorSantander(Items[i].Aforo, (Items[i].Plazo / 12), Items[i].Monto );

                      } else{		                                      
                        Items[i].GastosNotariales= ( (this.caso.gastosNotarial * Items[i].FactorDescuentoGastosNotariales) / 100) * this.caso.valorInmueble
                        Items[i].GastosIniciales= Items[i].Avaluo + Items[i].ComisionApertura + Items[i].GastosNotariales
                        
                      }                      
                      this.ans = Items[i]  
                      this.actualizaVariante(this.ans.GastosIniciales)  
                      this.updateCaso(this.caso.destino, this.caso.bancos)                    
                    }
                  }else{
                    if (Items[i].Variante == this.caso.variante) {
                      //console.log("COINCIDIR " + Items[i])
                      this.ans = Items[i]   
                      this.tablaAmortizacionBancos(this.ans,'cotiza');                               
                    }
                  }
                  
                }           
                //alert("aa "+this.caso.variante)     
                //alert(JSON.stringify(this.ans))
               // this.tablaAmortizacionBancos(this.ans,'cotiza');

              if (this.labelOrigen == 'TablaAmortizacion2') {
                //alert("entre IFFFF")
                this.labelOrigen = 'TablaAmortizacion1'
                this.banderaTabla1 = true;
                this.updateCaso(this.caso.destino, this.caso.bancos)
              }else{
                //alert("entre ELSEEEE")
                this.labelOrigen = 'TablaAmortizacion2'
              }


              /*response.data.objContenido[0].Origen = 'tablaAmortizacion2'
              var ans = response.data.objContenido[0]              
              this.tablaAmortizacionBancos(response.data.objContenido[0])

              if (this.labelOrigen == 'TablaAmortizacion2') {
                //alert("entre IFFFF")
                this.labelOrigen = 'TablaAmortizacion1'
                this.banderaTabla1 = true;
                this.updateCaso(this.caso.destino, this.caso.bancos)
              }else{
                //alert("entre ELSEEEE")
                this.labelOrigen = 'TablaAmortizacion2'
              }


              */
            } else {
						this.$vs.notify({
							title: 'Ocurrio un error en Cotiza',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
					// this.$vs.loading.close('#cotizacion > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})	
      },

    actualizaVariante(gatosIniciales){

      let self = this
      let objRequestUpdateVariante = {
            strApiKey: this.key,
            strMetodo: 'updateVariante',
            objVariante: {
              variante: this.caso.variante,
              gastosIniciales: gatosIniciales
              //GastoNotarial: this.caso.gastosNotarial
            },
          }
          this.$axios
            .post('/', objRequestUpdateVariante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
            .then((response) => {
              if (response.data.intEstatus == 0) {
                
              } else {
              this.$vs.notify({
                title: 'Ocurrio un error en Cotiza',
                text: response.data.strError,
                color: 'danger',
                position: 'top-right',
              })
            }
            // this.$vs.loading.close('#cotizacion > .con-vs-loading')
          })
          .catch(function (error) {
            self.$vs.notify({
              title: 'Ocurrio un error de sistema',
              text: error,
              color: 'danger',
              position: 'top-right',
            })
          })	
    },
    calculaFactorSantander(aforo, plazo, credito) {

        let aforoMultiplo = Math.ceil(aforo / 5) * 5; // obtiene multiplo de 5 mas cercano, si es multiplo exacto queda igual
        let factor;
        let montoAdicional = 0;

        if (aforoMultiplo >= 5 && aforoMultiplo <= 50) {
          factor = this.factorPorcentualSantander.aforoTipoA.find((item) => item.plazo == plazo);
        } else if (aforoMultiplo >= 55 && aforoMultiplo <= 70) {
          factor = this.factorPorcentualSantander.aforoTipoB.find((item) => item.plazo == plazo);
        }else if (aforoMultiplo == 75) {
          factor = this.factorPorcentualSantander.aforoTipoC.find((item) => item.plazo == plazo);
        }else if (aforoMultiplo == 80) {  
          factor = this.factorPorcentualSantander.aforoTipoD.find((item) => item.plazo == plazo);
        }else if (aforoMultiplo == 85) {
          factor = this.factorPorcentualSantander.aforoTipoE.find((item) => item.plazo == plazo);
        }else if (aforoMultiplo >= 90 && aforoMultiplo <= 100 ) {
          factor = this.factorPorcentualSantander.aforoTipoF.find((item) => item.plazo == plazo);
        }

        // verifica si encontro algun plazo valido
        if (!factor) {
          montoAdicional = 0;
          // console.log( 'No se encontro plazo', montoAdicional );
          return montoAdicional;
        }

        montoAdicional = ((factor.porcentaje / 100) * credito).toFixed(2);
        montoAdicional = Number(montoAdicional);
        // console.log( 'MontoData: ', factor.porcentaje, credito, plazo,  montoAdicional );
        return montoAdicional;
    },
    searchSegurosOtros(rowItem, producto = null, mesData = null) {         
      
		/**
		 * Se asume que puede existir un seguro de vida, daños, desempleo, y comision por cada fila
		 * [0] = seguro de vida
		 * [1] = seguro de danios
		 * [2] = seguro de desempleo
		 * [3] = comision
		 */
		let segurosOtros = [0,0,0,0];

		// console.log( rowItem );

		rowItem.forEach(item => {

			if ((item.Gasto.toLowerCase().includes("seguro de vida") || item.Gasto.toLowerCase().includes("seguro vida") || item.Gasto.toLowerCase().includes("seguros vida")) && 
				item.Sumable === "1") {          
				// seguro de vida
				segurosOtros[0] = item.Monto;

        // si es SCOTIABANK ADQUISICIÓN DE VIVIENDA FOVISSSTE PARA TODOS id 163
        if (producto.Variante == 163 && mesData.Mes == 0) {
          let todayVStart = new Date(); // fecha inicial
          let todayVEnd = new Date();
          todayVEnd = new Date(todayVEnd.setMonth(todayVEnd.getMonth()+1)); // fecha final
          todayVEnd.setDate(3); // dia de corte 3

          let formulaDiasA = (todayVEnd - todayVStart) / (1000 * 60 * 60 * 24); // resultado en milisegundos convertido a dias
          let seguroVidaMesCero = mesData.Capital * 0.000278 / 30 * formulaDiasA; // formula para mes 0, factor 0.000278
          segurosOtros[0] = seguroVidaMesCero;
        }

			} else if (item.Gasto.toLowerCase().includes("seguro de daños") && item.Sumable === "1") {
				// seguro de daños        
				segurosOtros[1] = item.Monto;

        // si es SCOTIABANK ADQUISICIÓN DE VIVIENDA FOVISSSTE PARA TODOS id 163
        if (producto.Variante == 163 && mesData.Mes == 0) {
          let todayDStart = new Date(); // fecha inicial
          let todayDEnd = new Date();
          todayDEnd = new Date(todayDEnd.setMonth(todayDEnd.getMonth()+1)); // fecha final
          todayDEnd.setDate(3); // dia de corte 3

          let formulaDiasB = (todayDEnd - todayDStart) / (1000 * 60 * 60 * 24); // resultado en milisegundos convertido a dias;
          let seguroDanioMesCero = producto.ValorPropiedad * 0.0003016 / 30 * formulaDiasB; // formula para mes 0, factor 0.0003016
          segurosOtros[1] = seguroDanioMesCero;
        }

			} else if ((item.Gasto.toLowerCase().includes("seguro de desempleo") || 
				item.Gasto.toLowerCase().includes("seguro desempleo")) && 
				item.Sumable === "1") {          
				// seguro de desempleo
				segurosOtros[2] = item.Monto;

			} else if (item.Gasto.toLowerCase().includes("comisión") && item.Sumable === "1") {
				// comisión        
				segurosOtros[3] = item.Monto;
			}
		});
		return segurosOtros;
	  }     
  },	
}
</script>

<style lang="scss" scoped>
.vx-card{
  margin-bottom: 1%;
}
.list-view-item {
    .item-name, .item-description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .item-name {
        -webkit-line-clamp: 2;
    }

    .item-description {
        -webkit-line-clamp: 5;
    }

    .grid-view-img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        transition: .35s;
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 4px 25px 0px rgba(0,0,0,.25);

        .grid-view-img{
            opacity: 0.9;
        }
    }
}
.exportPDF{
    margin-bottom: 3%;
    float: left;
    
}
.tablaAmortizacion{ 
  margin-left: 1%;    
  float: left;
  color: white;  
  padding-top: 0px;
  padding-bottom: 0px;
}
.export{
    margin-bottom: 3%;
    float: right;
}
  .favoritos{
    border: none !important;
    background: transparent !important;
  }
  .w2-full{
    padding-top: 0px !important;
    width: 100%;
  }
  .recorte{
    width: 170px;
    height: 45px;}
    
  .recorte2{
    width: 150px;
    height: 45px;}

  img{object-fit: cover !important;}
  
  .con-vs-dialog .vs-dialog footer{
  display: none !important;
  }
  .vuesax-app-is-ltr .con-vs-dialog .vs-dialog footer button {
    display: none !important;}

  @media screen and (max-width:915px) {
    .w2-full{
    padding-top: 0px !important;
    width: auto;
  }
    
  }

  .labelAmortizacion{
    text-align: left;
    font-weight: bold;
    margin-left: -5px;
    margin-top: 20px;
    padding-top: 1%;
    
  }

    .vs-dialog {
    position: fixed !important;
}

.con-vs-dialog {
    position: fixed!important;
    height: auto!important;
}
td {
    border: none;
    padding: 4px;
}
tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    font-size: 11px !important;

}

.con-vs-dialog {
    top: 0px; 
    /* z-index: 52005; */
}
.aportacion-indicador {
  background-color: #079DEF;
}
.aportacion-indicador td {
  color: white;
}
</style>
